import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"

import Testimonials from "../../components/testimonials"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const AboutPage = ({ data }) => {
  const aboutPageData = data.aboutPage.nodes[0]

  return (
    <Layout>
      <SEO
        title={aboutPageData.seo_title}
        description={aboutPageData.seo_description}
      />

      <div className="bg-gray-50 overflow-hidden lg:pt-4 lg:pb-16">
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
          <div className="mx-auto text-base max-w-prose lg:max-w-none">
            <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              {aboutPageData.pageHeading}
            </h1>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <Img
                      className="rounded-lg shadow-lg object-cover object-center"
                      fluid={aboutPageData.image.asset.fluid}
                    />
                  </div>
                  <figcaption className="mt-4 flex items-center text-base text-gray-500">
                    <svg
                      className="flex-none w-5 h-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="ml-2">
                      {aboutPageData.ownerNames}, {aboutPageData.ownerTitles}
                    </span>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="lg:-mt-4 prose prose-indigo text-base md:text-lg text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <BlockContent blocks={aboutPageData._rawPageDescription} />
            </div>
          </div>
        </div>
      </div>

      <Testimonials />
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    aboutPage: allSanityAboutPage {
      nodes {
        seo_title
        seo_description
        pageHeading
        _rawPageDescription
        image {
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
        ownerNames
        ownerTitles
      }
    }
  }
`
