import React from 'react'
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"

const Testimonials = () => {
  const { sanityReviewsSection } = useStaticQuery(graphql`
    query {
      sanityReviewsSection {
        reviews {
          _key
          _rawBody
          reviewer_name
          reviewer_description
          reviewer_image {
            asset {       
              fluid(maxWidth: 60) {
                ...GatsbySanityImageFluid
              }
            }
          }      
        }
      }
    }
  `);

  return (
    <section className="bg-white">
      <div className="max-w-7xl mx-auto md:grid md:grid-cols-2 lg:grid-cols-3 px-0 md:space-x-4 lg:space-x-6 divide-y divide-x-0 md:divide-y-0 md:divide-x-2 divide-gray-100">
        {sanityReviewsSection.reviews.slice(0, 3).map((review) => (
          <div key={review._key} className={`py-12 px-4 sm:px-6 md:py-12`}>          
            <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
              <div className="relative text-lg md:flex-grow">
                <svg className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-green-100" fill="currentColor" viewBox="0 0 32 32">
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <div className="prose relative text-green-700 font-medium">
                  <BlockContent blocks={review._rawBody} />
                </div>         
              </div>
              <footer className="mt-8">
                <div className="flex">
                  <div className="flex-shrink-0 inline-flex rounded-full border-2 border-green-600">
                    <Image className="h-12 w-12 rounded-full" fluid={review.reviewer_image.asset.fluid} />                    
                  </div>
                  <div className="ml-4">
                    <div className="text-base font-medium text-green-700">
                      {review.reviewer_name}
                    </div>
                    {review.reviewer_description && 
                    <div className="text-base font-medium text-gray-500">
                      {review.reviewer_description}
                    </div>}
                  </div>
                </div>
                <a href="https://www.facebook.com/ashbrooklandco/reviews/?ref=page_internal" target="_blank" rel="noopener noreferrer" className="mt-6 flex items-center text-gray-400 hover:text-gray-500 cursor-pointer text-sm">
                  <svg className="h-5 w-4" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                  </svg>
                  <span className="ml-1">
                    Read more on Facebook
                  </span>
                </a>
              </footer>
            </blockquote>
          </div>
        ))}        
      </div>
    </section>
  )
}

export default Testimonials
